import React, { useState, useEffect, useRef } from "react";
import "../homeHeader/header.css";
import "./extra.css";
import PhoneHeader from "./phone";
import { Link } from "react-router-dom";
import logo from "../../../../files/logo.png";
import { motion, useViewportScroll } from "framer-motion";

function ExtraHeader() {
  const { scrollY } = useViewportScroll();

  const [menuOpen, setMenuOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(true);

  const controlLogo = () => {
    if (window.scrollY > 40) {
      setShowLogo(false);
    } else {
      setShowLogo(true);
    }
  };

  const headerStyle1 = {
    background: "none",
    opacity: "0",
  };

  const headerStyle2 = {
    backgroundColor: "#2f4182",
    opacity: "0.7",
    width: "70px",
    height: "70px",
    borderRadius: "100%",
    position: "fixed",
    top: "30px",
    right: "86px",
    transition: "all 0.3s",
  };

  const headerStyle3 = {
    backgroundColor: "#2f4182",
    opacity: "0.7",
    width: "70px",
    height: "70px",
    borderRadius: "100%",
    position: "fixed",
    top: "25px",
    right: "16px",
    transition: "all 0.3s",
  };

  const [headerBackground, setHeaderBackground] = useState(headerStyle1);

  function update() {
    if (window.innerWidth < 970) {
      if (window.pageYOffset > window.innerHeight * 0.1) {
        if (window.innerWidth < 520) {
          setHeaderBackground(headerStyle3);
        } else {
          setHeaderBackground(headerStyle2);
        }
      } else {
        setHeaderBackground(headerStyle1);
      }
    }
  }

  useEffect(() => {
    return scrollY.onChange(() => update());
  });

  useEffect(() => {
    window.addEventListener("scroll", controlLogo);
    return () => {
      window.removeEventListener("scroll", controlLogo);
    };
  }, []);
  return (
    <div className='headerContainer extra'>
      <Link className='link' to='/'>
        <img className={showLogo ? "logo" : "logo hide"} src={logo} />
      </Link>

      <div className='phoneNav'>
        <div onClick={() => setMenuOpen(!menuOpen)}>
          <div className={menuOpen ? "hamburger open" : "hamburger"}>
            <div className={menuOpen ? `line active1` : "line"}></div>
            <div className={menuOpen ? `line active3` : "line"}></div>
            <div className={menuOpen ? `line active2` : "line"}></div>
          </div>
          <div style={headerBackground}></div>
        </div>

        <PhoneHeader menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      </div>
    </div>
  );
}

export default ExtraHeader;
