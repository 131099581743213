import React, { useState } from "react";
import backtesting from "../../../files/Conviction-merged.pdf";
import Modal from "react-modal";
import emailjs from "emailjs-com";

import { useQuery, useMutation } from "@apollo/client";
import { QUERY_USER } from "../../utils/queries";
import { REQUEST_REPORT } from "../../utils/mutations";
import Auth from "../../utils/auth";
import deloitte from "../../../files/deloitte.png";
import algorithm from "../../../files/Centrifuge Algorithm Review Report vF.pdf";
import assumptions from "../../../files/Backtesting Assumptions.pdf";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  overlay: { zIndex: 10 },
};

function Slides({ pptOpen, setPptOpen }) {
  const [slideButton, setSlidesButton] = useState("activeSlides");
  const [deloitteButton, setDeloitteButton] = useState("notActive");
  const [investButton, setInvestButton] = useState("notActive");
  const [checkboxStyle, setCheckboxStyle] = useState("individualCheckbox");
  const [termsAcceptedMessage, setTermsAcceptedMessage] =
    useState("termsAccepted");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [investorContainer, setInvestorContainer] =
    useState("investorsContainer");

  const [termsAgree, setTermsAgree] = useState(false);
  const [termsErrorMsg, setTermsErrorMsg] = useState("");

  const [termsContainer, setTermsContainer] = useState("termsContainer");
  const [nextTermsContainer, setNextTermsContainer] = useState(
    "hiddenTermsContainer"
  );
  const [requestSentContainer, setRequestSentContainer] = useState(
    "hiddenTermsContainer"
  );

  const email = Auth.getProfile().data.email;

  const { loading, error, data } = useQuery(QUERY_USER, {
    variables: { email },
  });

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    address: "",
    suburb: "",
    postcode: "",
    state: "",
    email: "",
    reportRequest: false,
  });

  const termsCheckboxHandler = () => {
    setTermsAgree(!termsAgree);
  };

  const termsError = () => {
    setTermsErrorMsg(
      "You must accept the terms and conditions before proceeding"
    );
  };

  const [reportRequest, { requestError, requestData }] =
    useMutation(REQUEST_REPORT);

  const [signupButton, setSignupButton] = useState("signupButton");
  const [loader, setLoader] = useState("hideLoader");
  const [requestSent, setRequestSent] = useState("hideRequestSent");

  function setForm() {
    if (data.user.reportRequest == true) {
      setSignupButton("hideSignupButton");
      setRequestSent("requestSent");
      setCheckboxStyle("hiddenCheckbox");
      setTermsAcceptedMessage("termsAccepted");
      setTermsContainer("hiddenTermsContainer");
      setNextTermsContainer("hiddenTermsContainer");
      setRequestSentContainer("requestSentContainer");
    } else if (requestSent === "requestSent") {
      setSignupButton("hideSignupButton");
      setRequestSent("requestSent");
      setCheckboxStyle("hiddenCheckbox");
      setTermsAcceptedMessage("termsAccepted");
      setTermsContainer("hiddenTermsContainer");
      setNextTermsContainer("hiddenTermsContainer");
      setRequestSentContainer("requestSentContainer");
    } else {
      setTermsAcceptedMessage("hiddenMsg");
    }
    setFormState({
      firstName: data.user.firstName,
      lastName: data.user.lastName,
      phone: "",
      job: "",
      business: "",
      abn: "",
      salutation: "",
      email: data.user.email,
      reportRequest: data.user.reportRequest,
    });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  function openModal(event) {
    event.preventDefault();
    setForm();
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const changeActiveSlides = (event) => {
    event.preventDefault();
    setPptOpen("slides");
    setSlidesButton("activeSlides");
    setDeloitteButton("notActive");
    setInvestButton("notActive");
    setSlideShow(
      "https://docs.google.com/presentation/d/e/2PACX-1vRZVgRWbDkKY-_GDQFGuV8aECR8WdtR0Kjje0oRK3k2g4qycnOboadjlqRFsJxscajsQkS1kzwD8P5J/embed?start=false&loop=false&delayms=3000"
    );
  };

  const changeActiveDeloitte = (event) => {
    event.preventDefault();
    setPptOpen("deloitte");
    setInvestorContainer("investorsContainer2");
    setSlidesButton("notActiveSlides");
    setDeloitteButton("active");
    setInvestButton("notActive");
    setSlideShow(
      "https://docs.google.com/presentation/d/e/2PACX-1vRZVgRWbDkKY-_GDQFGuV8aECR8WdtR0Kjje0oRK3k2g4qycnOboadjlqRFsJxscajsQkS1kzwD8P5J/embed?start=false&loop=false&delayms=3000"
    );
  };

  const changeActiveInvest = (event) => {
    event.preventDefault();
    setPptOpen("invest");
    setSlidesButton("notActiveSlides");
    setDeloitteButton("notActive");
    setInvestButton("active");
    setSlideShow(
      "https://docs.google.com/presentation/d/e/2PACX-1vRZVgRWbDkKY-_GDQFGuV8aECR8WdtR0Kjje0oRK3k2g4qycnOboadjlqRFsJxscajsQkS1kzwD8P5J/embed?start=false&loop=false&delayms=3000"
    );
  };

  const [slideshow, setSlideShow] = useState(
    "https://docs.google.com/presentation/d/e/2PACX-1vRZVgRWbDkKY-_GDQFGuV8aECR8WdtR0Kjje0oRK3k2g4qycnOboadjlqRFsJxscajsQkS1kzwD8P5J/embed?start=false&loop=false&delayms=3000"
  );

  const changePage = (event) => {
    event.preventDefault();
    setSlideShow(
      "https://docs.google.com/presentation/d/e/2PACX-1vRZVgRWbDkKY-_GDQFGuV8aECR8WdtR0Kjje0oRK3k2g4qycnOboadjlqRFsJxscajsQkS1kzwD8P5J/embed?start=false&loop=false&delayms=3000&slide=id.gf4f0b47df8_0_1910"
    );
  };

  const submitRequest = (event) => {
    event.preventDefault();

    if (termsAgree == false) {
      termsError();
    } else {
      setTermsErrorMsg("");
      setSignupButton("hideSignupButton");
      setLoader("loader");
      setCheckboxStyle("hiddenCheckbox");
      var templateParams = {};

      if (formState.abn === "") {
        templateParams = {
          salutation: formState.salutation,
          firstName: formState.firstName,
          lastName: formState.lastName,
          email: formState.email,
          phone: formState.phone,
          job: formState.job,
          business: formState.business,
          abn: "Did not answer",
        };
      } else {
        templateParams = {
          salutation: formState.salutation,
          firstName: formState.firstName,
          lastName: formState.lastName,
          email: formState.email,
          phone: formState.phone,
          job: formState.job,
          business: formState.business,
          abn: formState.abn,
        };
      }

      emailjs
        .send(
          "service_jrpkgf8",
          "template_g8184pc",
          templateParams,
          "user_qT2hm8aPSovTyXmNg9Otu"
        )
        .then(
          async (result) => {
            console.log(result.text);
            setLoader("hideLoader");
            setRequestSent("requestSent");
            try {
              const { reportRequested } = await reportRequest({
                variables: {
                  email: formState.email,
                  salutation: formState.salutation,
                  phone: formState.phone,
                  job: formState.job,
                  company: formState.business,
                  abn: formState.abn,
                },
              });
            } catch (requestError) {
              console.error(requestError);
            }
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setTermsContainer("hiddenTermsContainer");
    setNextTermsContainer("termsContainer");
  };

  if (loading) {
    return (
      <>
        <h1>Loading...</h1>
      </>
    );
  }

  return (
    <>
      <div className="slidesButtonDiv">
        <div className="mainButtonDiv">
          <button className={slideButton} onClick={changeActiveSlides}>
            Full Investor Presentation
          </button>
        </div>
        <button className={deloitteButton} onClick={changeActiveDeloitte}>
          Independent Verification
        </button>
        <button className={investButton} onClick={changeActiveInvest}>
          Invest Here / Request IM
        </button>
      </div>
      {pptOpen === "slides" ? (
        <div className="slidesContainer">
          <button className="notActive" onClick={changePage}>
            Skip Executive Summary
          </button>
          <iframe
            src={slideshow}
            className="googleSlides"
            frameBorder="0"
            allowFullScreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
          ></iframe>
          <h5 className="directions">
            Click the three stacked dots and select "Enter full screen" for the
            best viewing experience.
          </h5>
        </div>
      ) : (
        <div></div>
      )}
      {pptOpen === "deloitte" ? (
        <>
          <div className="investBody">
            <div className="deloitteStatement">
              <h3>Deloitte Statement</h3>
              <p>
                Deloitte was asked to factually assess the data used in the
                Backtesting Model as provided by Centrifuge Capital. This
                encapsulated data related to specific equities traded in
                Centrifuge’s Quantitative 200 and Conviction 30 investment funds
                and the corresponding entry and exit prices for such stock over
                a specific backtesting period of 3 January 2000 – 31 December
                2020.{" "}
              </p>
              <h4 className="boldDeloitte">
                Relying on the information (including the Backtesting
                Assumptions and Backtesting Database) provided by Centrifuge
                Capital and publicly available information, we have verified
                that the entry and exit prices for the specific stocks over the
                specific time period as outlined above were consistent with
                those indicated by Centrifuge Capital to be used in its
                algorithm, and the specific corresponding performance metrics
                (being fund returns, maximum drawdown, Sharpe ratios and
                comparisons to the S&P500 index, for which Centrifuge Capital
                provided formulae) when using such data and performing such
                calculations resulted in output which was consistent with the
                output provided by Centrifuge Capital in its Backtesting Model.{" "}
              </h4>
              <p>
                Deloitte relied upon information supplied by Centrifuge Capital
                as to what stocks it had selected and when these stocks were
                traded, and the assumptions set out by Centrifuge Capital in its
                Backtesting Assumptions document (dated 6th August 2021).
                Deloitte has not reviewed the algorithms used by Centrifuge
                Capital that determined the stocks to be reviewed nor how
                Centrifuge Capital utilises the algorithms. Deloitte does not
                make any representations as to the accuracy of the algorithm
                other than in respect to the specific data, time period and
                corresponding returns as tested above, nor any representation of
                future performance of the funds nor the future operations of
                Centrifuge Capital. Our work performed does not constitute an
                audit of Centrifuge Capital’s data, algorithm, or systems, and
                nor does the work performed by Deloitte imply an opinion or
                recommendation in respect to Centrifuge Capital’s offering.
              </p>
              <div className="partnerDiv">
                <p>Rick Shaw</p>
                <p>Partner</p>
                <img src={deloitte}></img>
              </div>
            </div>
            <p>
              Request access to the full report by clicking{" "}
              <a className="requestHere" onClick={openModal}>
                HERE
              </a>
            </p>
            <div className="linkDiv">
              <a
                className="investorLink"
                href={assumptions}
                target="_blank"
                rel="noreferrer"
              >
                Backtesting Assumptions.
              </a>
              {/* <a className='investorLink' href={algorithm} target='_blank'>
                Algorithm Review.
              </a> */}
              <a
                className="investorLink"
                href={backtesting}
                target="_blank"
                rel="noreferrer"
              >
                Backtesting Database.
              </a>
            </div>
          </div>
          <div className="sendRequestDiv">
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Add File"
            >
              <div className="closeContainer">
                <a className="modalFrontClose" onClick={closeModal}>
                  x
                </a>
              </div>
              <div className={termsContainer}>
                <h3>Request access to the full report.</h3>
                <form onSubmit={handleFormSubmit}>
                  <p>Please provide the following details.</p>
                  <div className="inputContainer2">
                    <select
                      className="form-input"
                      placeholder="Salutation"
                      name="salutation"
                      type="text"
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled selected>
                        Salutation
                      </option>
                      <option value="Mr">Mr</option>
                      <option value="Miss">Miss</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Ms">Ms</option>
                      <option value="Dr">Dr</option>
                      <option value="They/Them">They/Them</option>
                      <option value="Other">Other</option>
                    </select>
                    <input
                      className="form-input"
                      placeholder="First Name"
                      name="firstName"
                      type="text"
                      value={formState.firstName}
                      onChange={handleChange}
                      required
                    />
                    <input
                      className="form-input"
                      placeholder="Last Name"
                      name="lastName"
                      type="text"
                      value={formState.lastName}
                      onChange={handleChange}
                      required
                    />
                    <input
                      className="form-input"
                      placeholder="Your email"
                      name="email"
                      type="email"
                      value={formState.email}
                      onChange={handleChange}
                      required
                      disabled
                    />
                    <input
                      className="form-input"
                      placeholder="Phone"
                      name="phone"
                      type="number"
                      value={formState.phone}
                      onChange={handleChange}
                      required
                    />
                    <input
                      className="form-input"
                      placeholder="Job / Title"
                      name="job"
                      type="text"
                      value={formState.job}
                      onChange={handleChange}
                      required
                    />
                    <input
                      className="form-input"
                      placeholder="Company Name"
                      name="business"
                      type="text"
                      value={formState.business}
                      onChange={handleChange}
                      required
                    />
                    <input
                      className="form-input"
                      placeholder="ABN / ACN - if applicable"
                      name="abn"
                      type="number"
                      value={formState.abn}
                      onChange={handleChange}
                    />

                    <button
                      id="requestButton"
                      className={signupButton}
                      type="submit"
                    >
                      Next
                    </button>
                  </div>
                </form>
              </div>

              <div className={requestSentContainer}>
                <h3>Already Requested.</h3>

                <p>
                  Your request to be granted access to the full Deloitte Report
                  has been forwarded on. If approved, you will be sent the full
                  report via email.
                </p>
              </div>

              <div className={nextTermsContainer}>
                <h3>Request access to the full report.</h3>
                <h5>
                  Please read and accept the following terms and conditions:
                </h5>
                <div className="innerTermsContainer">
                  <p>
                    Access to Deloitte’s Report in respect of Centrifuge
                    Capital’s Backtesting Review
                  </p>
                  <p>
                    You have asked for access to Deloitte’s Centrifuge Capital’s
                    Backtesting Review dated October 2021 (the Report). This
                    letter sets out the terms on which the requestor of this
                    Report (You) may be granted access to the Report, should
                    written consent be granted, such consent being at our
                    discretion and subject to these specific conditions
                    (Consent.
                  </p>
                  <p>
                    By signing this letter or otherwise accepting the terms
                    below, You agree and acknowledge that the following terms
                    and conditions will apply if You are provided access to the
                    Report:
                  </p>
                  <ul>
                    <li>
                      Our work was performed and the Report was prepared:
                      <li>
                        a) for Centrifuge Capital (the Client) only on the
                        Client’s instructions and from information provided by
                        the Client; and
                      </li>
                      <li>b) solely for the Client’s benefit.</li>
                    </li>
                    <li>
                      The Report was prepared for the Client’s purposes only.
                      You may have access to the Report for informational
                      purposes only (Purpose) on an as is basis.
                    </li>
                    <li>
                      You may not rely on the Report. If we provide You with a
                      copy of the Report, we do not accept any responsibility or
                      owe a duty of care to You or anyone else in that regard.
                    </li>
                    <li>
                      You are responsible for any decisions You make in
                      connection with the management, conduct and operation of
                      your business, interests and affairs as a result of your
                      access to the Report.
                    </li>
                    <li>
                      Our work and the Report does not address any matters
                      arising after the date of the Report or the date detailed
                      in the Report.
                    </li>
                    <li>
                      The Report is confidential and must be treated as such by
                      You.
                    </li>
                    <li>
                      You may not provide a copy of the Report to any other
                      party.
                    </li>
                    <li>
                      You agree:
                      <li>
                        a) that You will not make any claim or demand or bring
                        any actions or proceedings against Deloitte or any
                        officer, partner, employee, consultant, agent,
                        contractor or subcontractor of either of Deloitte or a
                        Member Firm (Representatives) in connection with the
                        Report or your access to it. You agree to release and
                        forever discharge us and our Representatives from any
                        such claims, demands, actions or proceedings; and
                      </li>
                      <li>
                        b) You indemnify Deloitte against all actions, claims,
                        suits, demands, claim for costs or expenses, or any
                        other proceedings arising out of or in connection with
                        any breach by you of the terms of this letter.
                      </li>
                    </li>
                    <li>
                      You acknowledge that damages may not be a sufficient
                      remedy for any breach of this agreement and that we may be
                      entitled to apply to a court for an order for specific
                      performance or injunctive relief (as appropriate) as a
                      remedy for any breach or threatened breach in addition to
                      any other remedies available to us at law or in equity.
                    </li>
                    <li>
                      Deloitte may use the information You provide to the Client
                      to complete its internal take on and review procedures but
                      will not disclose or use your information for any other
                      purposes.
                    </li>
                    <li>
                      This letter forms a binding agreement between You and
                      Deloitte which is governed by the laws of New South Wales
                      and each party irrevocably submits to the jurisdiction of
                      the courts exercising jurisdiction in that State.
                    </li>
                    <li>
                      By checking the box to accept, You are agreeing to the
                      terms and conditions of the release of this letter, and
                      You acknowledge your acceptance of these terms.
                    </li>
                  </ul>
                  <h6 className="smallDeloitte">
                    Deloitte refers to one or more of Deloitte Touche Tohmatsu
                    Limited (“DTTL”), its global network of member firms, and
                    their related entities (collectively, the “Deloitte
                    organisation”). DTTL (also referred to as “Deloitte Global”)
                    and each of its member firms and related entities are
                    legally separate and independent entities, which cannot
                    obligate or bind each other in respect of third parties.
                    DTTL and each DTTL member firm and related entity is liable
                    only for its own acts and omissions, and not those of each
                    other. DTTL does not provide services to clients. Please see
                    www.deloitte.com/about to learn more.
                  </h6>
                  <h6 className="smallDeloitte">
                    Deloitte is a leading global provider of audit and
                    assurance, consulting, financial advisory, risk advisory,
                    tax and related services. Our global network of member firms
                    and related entities in more than 150 countries and
                    territories (collectively, the “Deloitte organisation”
                    serves four out of five Fortune Global 500® companies. Learn
                    how Deloitte’s approximately 312,000 people make an impact
                    that matters at www.deloitte.com.
                  </h6>
                  <h6 className="smallDeloitte">
                    Deloitte Asia Pacific: Deloitte Asia Pacific Limited is a
                    company limited by guarantee and a member firm of DTTL.
                    Members of Deloitte Asia Pacific Limited and their related
                    entities, each of which are separate and independent legal
                    entities, provide services from more than 100 cities across
                    the region, including Auckland, Bangkok, Beijing, Hanoi,
                    Hong Kong, Jakarta, Kuala Lumpur, Manila, Melbourne, Osaka,
                    Seoul, Shanghai, Singapore, Sydney, Taipei and Tokyo.
                  </h6>
                  <h6 className="smallDeloitte">
                    Deloitte Australia: The Australian partnership of Deloitte
                    Touche Tohmatsu is a member of Deloitte Asia Pacific Limited
                    and the Deloitte organisation. As one of Australia’s leading
                    professional services firms, Deloitte Touche Tohmatsu and
                    its affiliates provide audit, tax, consulting, risk
                    advisory, and financial advisory services through
                    approximately 8000 people across the country. Focused on the
                    creation of value and growth, and known as an employer of
                    choice for innovative human resources programs, we are
                    dedicated to helping our clients and our people excel. For
                    more information, please visit our web site at
                    https://www2.deloitte.com/au/en.html.
                  </h6>
                  <h6 className="smallDeloitte">
                    Liability limited by a scheme approved under Professional
                    Standards Legislation.
                  </h6>
                  <h6 className="smallDeloitte">
                    Member of Deloitte Asia Pacific Limited and the Deloitte
                    organisation.
                  </h6>
                  <h6 className="smallDeloitte">
                    ©2021 Deloitte Touche Tohmatsu
                  </h6>
                </div>

                <div className={checkboxStyle}>
                  <input
                    type="checkbox"
                    id="termsAgree"
                    onChange={termsCheckboxHandler}
                    checked={termsAgree}
                  />
                  <label className="termsCheckbox" htmlFor="agree">
                    {" "}
                    <p>
                      I agree to the <b>terms and conditions</b>
                    </p>
                  </label>
                </div>
                <div className="errorMsg">{termsErrorMsg}</div>
                <button className={signupButton} onClick={submitRequest}>
                  Submit
                </button>
                <h4 className={termsAcceptedMessage}>
                  You have accepted the terms and conditions.
                </h4>
                <h3 className={requestSent}>Request has been sent!</h3>
                <div className={loader}>
                  <svg width="200" height="200" viewBox="0 0 100 100">
                    <polyline
                      className="line-cornered stroke-still"
                      points="0,0 100,0 100,100"
                      stroke-width="10"
                      fill="none"
                    ></polyline>
                    <polyline
                      className="line-cornered stroke-still"
                      points="0,0 0,100 100,100"
                      stroke-width="10"
                      fill="none"
                    ></polyline>
                    <polyline
                      className="line-cornered stroke-animation"
                      points="0,0 100,0 100,100"
                      stroke-width="10"
                      fill="none"
                    ></polyline>
                    <polyline
                      className="line-cornered stroke-animation"
                      points="0,0 0,100 100,100"
                      stroke-width="10"
                      fill="none"
                    ></polyline>
                  </svg>
                </div>
              </div>
            </Modal>
          </div>
        </>
      ) : (
        <div></div>
      )}
      {pptOpen === "invest" ? (
        <>
          <div className="investBody2">
            <a
              className="investorLink2"
              href="https://www.registrydirect.com.au/offer/centrifuge-capital-quantitative-long-equities-fund/"
              target="_blank"
              rel="noreferrer"
            >
              Click here to enquire into our Quantitative 200 fund.
            </a>

            <a
              className="investorLink2"
              href="https://www.registrydirect.com.au/offer/centrifuge-capital-conviction-long-equities-fund/"
              target="_blank"
              rel="noreferrer"
            >
              Click here to enquire into our Conviction 30 fund.
            </a>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default Slides;
