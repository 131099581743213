import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Auth from "../../utils/auth";
import {
  faSolid,
  faArrowAltCircleDown,
} from "@fortawesome/free-solid-svg-icons";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import logo from "../../../files/logo.png";
import "./landing.css";

const Landing = () => {
  const [formState, setFormState] = useState({ amount: 100000, time: 21 });
  const [data, setData] = useState();
  const [minValue, setMinValue] = useState(50000);
  const [maxValue, setMaxValue] = useState(250000);
  const [convictionReturn, setConvictionReturn] = useState({
    value: 0,
    percent: 0,
  });
  const [quantReturn, setQuantReturn] = useState({ value: 0, percent: 0 });
  const [spReturn, setSpReturn] = useState({ value: 0, percent: 0 });

  const currencyFormatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  useEffect(() => {
    handleInvest();
  }, []);

  const handleInvest = () => {
    var totalSp = parseInt(formState.amount);
    var totalQ = parseInt(formState.amount);
    var totalC = parseInt(formState.amount);
    var allData = [];
    var values = [totalSp, totalQ, totalC];

    if (formState.time <= 21) {
      console.log("made it to the initital");
      console.log(formState.time);
      console.log(formState.amount);
      console.log(totalSp);
      var time = 21 - formState.time;
      for (let i = time; i < 22; i++) {
        if (i === time) {
          var oldData = {
            Year: 2000 + time,
            "Conviction 30 Fund": parseInt(formState.amount),
            "Quantitative 200 Fund": parseInt(formState.amount),
            "S&P500": parseInt(formState.amount),
          };
          allData.push(oldData);
        }
        if (i === 0) {
          var newSp = totalSp + totalSp * -0.1013918467;
          var newQ = totalQ + totalQ * 0.1955160411;
          var newC = totalC + totalC * 0.2321948308;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2001,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 1) {
          var newSp = totalSp + totalSp * -0.1304269317;
          var newQ = totalQ + totalQ * 0.05062407669;
          var newC = totalC + totalC * 0.07470665713;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2002,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 2) {
          var newSp = totalSp + totalSp * -0.2336596396;
          var newQ = totalQ + totalQ * -0.1171686426;
          var newC = totalC + totalC * -0.1092852211;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2003,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 3) {
          var newSp = totalSp + totalSp * 0.2638039993;
          var newQ = totalQ + totalQ * 0.3008485441;
          var newC = totalC + totalC * 0.2636121776;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2004,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 4) {
          var newSp = totalSp + totalSp * 0.08993452411;
          var newQ = totalQ + totalQ * 0.2038304359;
          var newC = totalC + totalC * 0.1894227706;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2005,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 5) {
          var newSp = totalSp + totalSp * 0.03001022658;
          var newQ = totalQ + totalQ * 0.09253234708;
          var newC = totalC + totalC * 0.1081215185;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2006,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 6) {
          var newSp = totalSp + totalSp * 0.1361943174;
          var newQ = totalQ + totalQ * 0.1162598029;
          var newC = totalC + totalC * 0.1103777444;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2007,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 7) {
          var newSp = totalSp + totalSp * 0.03529573066;
          var newQ = totalQ + totalQ * 0.06053612087;
          var newC = totalC + totalC * 0.2193294259;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2008,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 8) {
          var newSp = totalSp + totalSp * -0.3848579306;
          var newQ = totalQ + totalQ * -0.344209387;
          var newC = totalC + totalC * -0.3331137436;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2009,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 9) {
          var newSp = totalSp + totalSp * 0.2345419049;
          var newQ = totalQ + totalQ * 0.2799541157;
          var newC = totalC + totalC * 0.354936701;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2010,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 10) {
          var newSp = totalSp + totalSp * 0.1278271385;
          var newQ = totalQ + totalQ * 0.1643858937;
          var newC = totalC + totalC * 0.04864951047;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2011,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 11) {
          var newSp = totalSp + totalSp * -0.00003183666;
          var newQ = totalQ + totalQ * 0.05428949644;
          var newC = totalC + totalC * 0.1282301071;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2012,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 12) {
          var newSp = totalSp + totalSp * 0.1340569093;
          var newQ = totalQ + totalQ * 0.1530379201;
          var newC = totalC + totalC * 0.159918082;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2013,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 13) {
          var newSp = totalSp + totalSp * 0.2960124957;
          var newQ = totalQ + totalQ * 0.2977757944;
          var newC = totalC + totalC * 0.3842299244;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2014,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 14) {
          var newSp = totalSp + totalSp * 0.1139063379;
          var newQ = totalQ + totalQ * 0.1336898555;
          var newC = totalC + totalC * 0.07056600314;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2015,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 15) {
          var newSp = totalSp + totalSp * -0.00726599721;
          var newQ = totalQ + totalQ * 0.07747306528;
          var newC = totalC + totalC * 0.1134762197;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2016,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 16) {
          var newSp = totalSp + totalSp * 0.09535022667;
          var newQ = totalQ + totalQ * 0.1098867721;
          var newC = totalC + totalC * 0.1065195318;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2017,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 17) {
          var newSp = totalSp + totalSp * 0.1941996552;
          var newQ = totalQ + totalQ * 0.2731516039;
          var newC = totalC + totalC * 0.3593996481;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2018,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 18) {
          var newSp = totalSp + totalSp * -0.0623725985;
          var newQ = totalQ + totalQ * -0.00008249504;
          var newC = totalC + totalC * 0.06415913915;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2019,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 19) {
          var newSp = totalSp + totalSp * 0.2887807023;
          var newQ = totalQ + totalQ * 0.3751024596;
          var newC = totalC + totalC * 0.4900905167;

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2020,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        } else if (i === 20) {
          var newSp = totalSp + totalSp * 0.1625892306;
          var newQ = totalQ + totalQ * 0.2305033348;
          var newC = totalC + totalC * 0.2191735295;

          console.log(totalSp);
          console.log(totalSp * 0.1625892306);
          console.log(newSp);

          totalSp = Math.round(newSp);
          totalQ = Math.round(newQ);
          totalC = Math.round(newC);

          const newData = {
            Year: 2021,
            "Conviction 30 Fund": totalC,
            "Quantitative 200 Fund": totalQ,
            "S&P500": totalSp,
          };
          allData.push(newData);
          values.push(newSp, newQ, newC);
        }
      }
      setMinValue(Math.round((Math.min(...values) - 10000) / 1000) * 1000);
      setMaxValue(Math.round((Math.max(...values) + 30000) / 1000) * 1000);
      setConvictionReturn({
        value: currencyFormatter.format(totalC),
        percent: (
          ((totalC / formState.amount) ** (1 / formState.time) - 1) *
          100
        ).toFixed(2),
      });
      setQuantReturn({
        value: currencyFormatter.format(totalQ),
        percent: (
          ((totalQ / formState.amount) ** (1 / formState.time) - 1) *
          100
        ).toFixed(2),
      });
      setSpReturn({
        value: currencyFormatter.format(totalSp),
        percent: (
          ((totalSp / formState.amount) ** (1 / formState.time) - 1) *
          100
        ).toFixed(2),
      });
      setData(allData);
    }
  };

  function goToLogin(event) {
    event.preventDefault();
    window.location.assign("/login");
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className='custom-tooltip'>
          <p className='labelMain'>{`Year: ${payload[0].payload.Year}`}</p>
          <p className='label'>{`Conviction 30: ${currencyFormatter.format(
            payload[0].value
          )}`}</p>
          <p className='label'>{`Quantitative 200: ${currencyFormatter.format(
            payload[1].value
          )}`}</p>
          <p className='label'>{`S&P500: ${currencyFormatter.format(
            payload[2].value
          )}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className='landingContainer'>
        <div className='landingText'>
          <h2>
            Equity Investment Funds employing Artifical Intelligence to find the
            one in a million.
          </h2>
          <Link
            className='teamButton'
            to={Auth.loggedIn() ? "/investors" : "/login"}
          >
            Investor Overview
          </Link>
          <Link className='performanceLink' to='/'>
            Our Performance
          </Link>
        </div>

        <div className='centrifugeContainer'>
          <div id='inner-orbit'></div>
          <div id='middle-orbit'></div>
          <div id='outer-orbit'>
            <div className='outer-orbit-cirlces'></div>
          </div>
        </div>
        {/* <div className='landingHeader'>
          <p className='name'>Centrifuge Capital</p>
          <p className='text'>AI Driven Strategies for the Capital Markets</p>
        </div>
        <div className='graphContainer'>
          <div className='investFormContainer'>
            <form className='investForm'>
              <p className='oneLiner'>
                See how you would have performed if you had invested in
                Centrifuge Capital's funds since the year 2000:
              </p>
              <p className='formLabel'>Amount Invested:</p>
              <input
                className='form-input'
                placeholder='100000'
                name='amount'
                type='number'
                value={formState.amount}
                onChange={handleChange}
              />
              <p className='formLabel'>Length of Investment (max 21 years):</p>
              <input
                className='form-input'
                placeholder='21'
                name='time'
                type='number'
                max='21'
                value={formState.time}
                onChange={handleChange}
              />
              <button
                className='investButton'
                style={{ cursor: "pointer" }}
                type='button'
                onClick={handleInvest}
              >
                Calculate
              </button>
            </form>
            <p className='b'>
              <button className='investButton' onClick={goToLogin}>
                Login/Register
              </button>{" "}
              for the Investor Overview
            </p>
            <div className='returnContainer'>
              <h4>Total Return:</h4>
              <p>{`Conviction 30: ${convictionReturn.value} (${convictionReturn.percent}% annualised)`}</p>
              <p>{`Quantitative 200: ${quantReturn.value} (${quantReturn.percent}% annualised)`}</p>
              <p>{`S&P500: ${spReturn.value} (${spReturn.percent}% annualised)`}</p>
            </div>
          </div>
          <ResponsiveContainer className='graph' width='100%' height='100%'>
            <LineChart
              width={1000}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 30,
              }}
            >
              <YAxis
                yAxisId='right'
                orientation='right'
                stroke='#ffffff'
                type='number'
                domain={[minValue, maxValue]}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#2a4091",
                  borderRadius: "7px",
                  color: "white",
                }}
                content={<CustomTooltip />}
              />
              <Legend
                wrapperStyle={{
                  position: "relative",
                  marginBottom: "100px",
                  fontWeight: "300",
                }}
              />
              <Line
                yAxisId='right'
                type='monotone'
                dataKey='Conviction 30 Fund'
                stroke='#13ed07'
                strokeWidth='4px'
                dot={false}
              />
              <Line
                yAxisId='right'
                type='monotone'
                dataKey='Quantitative 200 Fund'
                stroke='#0fc2d6'
                strokeWidth='4px'
                dot={false}
              />
              <Line
                yAxisId='right'
                type='monotone'
                dataKey='S&P500'
                stroke='#ff4e3b'
                strokeWidth='4px'
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className='graphDisclaimerDiv'>
          <p className='graphDisclaimer'>
            Past performance is not indicative of future performance. Specific
            risks of the fund may impact on the possibility of such a return in
            the future. Click <a href='#learn'>HERE</a> to see our full
            disclaimer.
          </p>
        </div>
      </div>
      <div className='landingContainer2'>
        <div className='landingHeader'>
          <p className='name'>Centrifuge Capital</p>
          <p className='text'> AI Driven Strategies for the Capital Markets</p>
        </div>
        <div className='c'>
          <button className='investButton' onClick={goToLogin}>
            Login/Register
          </button>
          <p>for the Investor Overview</p>
        </div> */}
      </div>
    </>
  );
};

export default Landing;
