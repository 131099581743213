import React from "react";

import "./body.css";

import About from "./about";
import Performance from "./performance";
import What from "./what";

const Body = () => {
  return (
    <div className='componentsContainer'>
      <div className='aboutComponent'>
        <About />
        <Performance />
        <What />
      </div>
    </div>
  );
};

export default Body;
