import React from "react";
import { Link } from "react-router-dom";

import Landing from "../features/home/landing";
import Body from "../features/home/body";
import Footer from "../features/home/footer";
import "./home.css";

const Home = () => {
  return (
    <main className='homeContainer'>
      <Landing />
      <div className='bodyContainer'>
        <Body />
      </div>
      <Footer />
    </main>
  );
};

export default Home;
