import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Footer from "../features/home/footer";

import "./aboutUs.css";

const AboutUs = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);

    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <>
      <div className='aboutUsContainer'>
        <div className='innerAboutUsContainer'>
          <Particles
            id='tsparticles1'
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              style: {
                position: "absolute",
                height: "100%",
                width: "100vw",
                zIndex: 0,
              },
              fpsLimit: 120,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  onDiv: {
                    selectors: "#repulse-div",
                    enable: true,
                    mode: "repulse",
                    type: "rectangle",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 75,
                    duration: 0.2,
                  },
                },
              },
              particles: {
                color: {
                  value: "#1f2c5c",
                },
                links: {
                  color: "#1f2c5c",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: false,
                },
                move: {
                  directions: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 1,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 70,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 5 },
                },
              },
              detectRetina: true,
            }}
          />
          <h2 id='repulse-div'>About Us</h2>
          <p className='aboutP'>
            Centrifuge Capital is a number of Equity Investment Funds that
            employ AI-driven, Deep Learning, Pattern Recognition and
            Quantitative strategies, each with varying risk and return profiles.
          </p>
          <p className='aboutP'>
            All strategies and funds are:
            <ul>
              <li>- Mathematically/Algorithmically based -</li>
              <li>- Automated Machine Traded -</li>
              <li>- With no human emotion, bias or Equity selection -</li>
            </ul>
          </p>
          <p className='aboutP'>
            Our algorithms have been honed over 8 years and are designed and
            trained to generate sustainable outperformance of the market.
          </p>
          <p className='aboutP'>
            At launch, we are offering access to two distinct funds, each
            trading public Equities – the Quantitative 200, and Conviction 30.{" "}
          </p>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
