import React, { useState } from "react";
import "../../homeHeader/phone/phone.css";
import { Link } from "react-router-dom";
import Auth from "../../../../utils/auth";

function PhoneHeader({ menuOpen, setMenuOpen }) {
  const logout = (event) => {
    event.preventDefault();
    Auth.logout();
    setMenuOpen(!menuOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div id='menu' className={menuOpen ? "open" : null}>
        <div id='menu-items'>
          {Auth.loggedIn() ? (
            <>
              <Link className='menu-item' to='/' onClick={toggleMenu}>
                Home
              </Link>
              <Link className='menu-item' to='/about' onClick={toggleMenu}>
                About Us
              </Link>
              <Link className='menu-item' to='/disclaimer' onClick={toggleMenu}>
                Disclaimer
              </Link>
              <Link className='menu-item' to='/investors' onClick={toggleMenu}>
                Investor Overview
              </Link>
              <a className='menu-item' onClick={logout}>
                Logout
              </a>
            </>
          ) : (
            <>
              <Link className='menu-item' to='/' onClick={toggleMenu}>
                Home
              </Link>
              <Link className='menu-item' to='/about' onClick={toggleMenu}>
                About Us
              </Link>
              <Link className='menu-item' to='/disclaimer' onClick={toggleMenu}>
                Disclaimer
              </Link>
              <Link className='menu-item' to='/login'>
                Investor Overview
              </Link>
            </>
          )}
        </div>
        <div className='menu-background-pattern'></div>
      </div>
    </>
  );
}

export default PhoneHeader;
