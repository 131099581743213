import React, { useState, useEffect } from "react";
import { motion, useViewportScroll } from "framer-motion";

import "./what.css";
import CreditSuisse from "../../../files/creditSuisse.png";
import boutiqueCapital from "../../../files/boutiqueCapital.png";
import corrs from "../../../files/Corrs.png";
import invast from "../../../files/Invast.png";
import JPMorgan from "../../../files/JPMorgan.png";
import william from "../../../files/William-Buck-logo.png";

function What() {
  const { scrollY } = useViewportScroll();
  const [hidden1, setHidden1] = useState(true);
  const [hidden2, setHidden2] = useState(true);

  function update() {
    if (window.innerWidth > 1300) {
      if (scrollY?.current < window.innerHeight * 1.7) {
        setHidden1(true);
      } else {
        setHidden1(false);
      }

      if (scrollY?.current < window.innerHeight * 1.8) {
        setHidden2(true);
      } else {
        setHidden2(false);
      }
    } else if (window.innerWidth < 630) {
      if (scrollY?.current < 1550) {
        setHidden1(true);
      } else {
        setHidden1(false);
      }

      if (scrollY?.current < 1600) {
        setHidden2(true);
      } else {
        setHidden2(false);
      }
    } else {
      if (scrollY?.current < 1450) {
        setHidden1(true);
      } else {
        setHidden1(false);
      }

      if (scrollY?.current < 1600) {
        setHidden2(true);
      } else {
        setHidden2(false);
      }
    }
  }

  useEffect(() => {
    return scrollY.onChange(() => update());
  });

  const variants = {
    /** this is the "visible" key and it's correlating styles **/
    visible: { opacity: 1, y: 0 },
    /** this is the "hidden" key and it's correlating styles **/
    hidden: { opacity: 0, y: -70 },
    visible2: { opacity: 1, y: 0 },
    /** this is the "hidden" key and it's correlating styles **/
    hidden2: { opacity: 0, y: 0 },
  };

  return (
    <div className='whatContainer'>
      <div id='what'></div>
      <div className='innerAdministationContainer'>
        <motion.div
          variants={variants}
          animate={hidden1 ? "hidden" : "visible"}
          transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.8 }}
          className='administrationHeading'
        >
          <h2>Institutional-Grade Administration</h2>
        </motion.div>

        <motion.div
          variants={variants}
          animate={hidden2 ? "hidden2" : "visible2"}
          transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
          className='logoContainer'
        >
          <div className='mainLogos'>
            <img src={CreditSuisse} alt='Credit Suisse'></img>
            <img src={JPMorgan} alt='JPMorgan'></img>
          </div>

          <img src={boutiqueCapital} alt='Boutique Capital'></img>
          <img src={corrs} alt='Corrs Chambers Westgarth'></img>
          <img src={invast} alt='Invast'></img>
          <img src={william} alt='William Buck'></img>
        </motion.div>
      </div>
      {/* <div className='administrationBorderContainer'>
        <div className='administrationBorder'></div>
      </div> */}
    </div>
  );
}

export default What;
