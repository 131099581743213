import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { motion, useViewportScroll } from "framer-motion";

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import "./about.css";

const About = () => {
  const { scrollY } = useViewportScroll();
  const [hidden1, setHidden1] = useState(true);

  function update() {
    if (window.innerWidth >= 630) {
      if (scrollY?.current < window.innerHeight / 4) {
        setHidden1(true);
      } else {
        setHidden1(false);
      }
    } else {
      if (scrollY?.current < 180) {
        setHidden1(true);
      } else {
        setHidden1(false);
      }
    }
  }

  useEffect(() => {
    return scrollY.onChange(() => update());
  });

  const variants = {
    /** this is the "visible" key and it's correlating styles **/
    visible: { opacity: 1, y: 0 },
    /** this is the "hidden" key and it's correlating styles **/
    hidden: { opacity: 0, y: -70 },
    visible2: { opacity: 1, y: 0 },
    /** this is the "hidden" key and it's correlating styles **/
    hidden2: { opacity: 0, y: 0 },
  };

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);

    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div className='aboutContainer'>
      <div id='about'></div>

      <motion.div
        variants={variants}
        animate={hidden1 ? "hidden2" : "visible2"}
        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
      >
        <Particles
          id='tsparticles'
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            style: {
              position: "relative",
              height: "60vh",
              width: "40vw",
              zIndex: 1,
              // top: "-40px",
              left: "50px",
              // left: "30%",
            },
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 75,
                  duration: 0.2,
                },
              },
            },
            particles: {
              color: {
                value: "#1f2c5c",
              },
              links: {
                color: "#1f2c5c",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: false,
              },
              move: {
                directions: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 3,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 100,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
          }}
        />
      </motion.div>
      <div className='aboutText'>
        <motion.div
          variants={variants}
          animate={hidden1 ? "hidden" : "visible"}
          transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
          className='aboutText1'
        >
          <h1>Centrifuge Capital</h1>
          <h2>
            is a number of Equity Investment Funds that employ AI-driven, Deep
            Learning, Pattern Recognition and Quantitative strategies, each with
            varying risk and return profiles.
          </h2>

          <Link className='moreButton' to='/about'>
            More about us
          </Link>
        </motion.div>
      </div>

      {/* <div className='innerAboutContainer'>
        <div className='aboutHeader'>
          <h1>Who we are</h1>
        </div>
        <div className='aboutText'>
          <p>
            Centrifuge Capital is a number of Equity Investment Funds that
            employ AI-driven, Deep Learning, Pattern Recognition and
            Quantitative strategies, each with varying risk and return profiles.
          </p>
          <p>
            All strategies and funds are:
            <ul>
              <li>Mathematically/Algorithmically based;</li>
              <li>Automated Machine Traded;</li>
              <li>With no human emotion, bias or Equity selection.</li>
            </ul>
          </p>
          <p>
            Our algorithms have been honed over 8 years and are designed and
            trained to generate sustainable outperformance of the market.
          </p>
          <p>
            At launch, we are offering access to two distinct funds, each
            trading public Equities – the Quantitative 200, and Conviction 30.{" "}
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default About;
