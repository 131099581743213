import React, { useState, useEffect, useRef } from "react";
import "./header.css";
import PhoneHeader from "./phone";
import { Link } from "react-router-dom";
import logo from "../../../../files/logo.png";
import { motion, useViewportScroll } from "framer-motion";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(true);

  const { scrollY } = useViewportScroll();
  const [colour1, setColour1] = useState("white");
  const [colour2, setColour2] = useState("white");
  const [colour3, setColour3] = useState("white");

  const headerStyle1 = {
    background: "none",
    opacity: "0",
  };

  const headerStyle2 = {
    backgroundColor: "white",
    opacity: "0.7",
    width: "70px",
    height: "70px",
    borderRadius: "100%",
    position: "absolute",
    top: "25px",
    right: "86px",
    transition: "all 0.3s",
  };

  const headerStyle3 = {
    backgroundColor: "white",
    opacity: "0.7",
    width: "60px",
    height: "60px",
    borderRadius: "100%",
    position: "absolute",
    top: "30px",
    right: "21px",
    transition: "all 0.3s",
  };

  const [headerBackground, setHeaderBackground] = useState(headerStyle1);

  function update() {
    if (
      window.innerWidth > 850 ||
      (window.innerWidth > 650 && window.innerWidth <= 750)
    ) {
      if (window.pageYOffset > window.innerHeight * 1.05) {
        setColour1("#2f4182");
      } else {
        setColour1("white");
      }

      if (window.pageYOffset > window.innerHeight * 1.04) {
        setColour2("#2f4182");
      } else {
        setColour2("white");
      }

      if (window.pageYOffset > window.innerHeight * 1.03) {
        setColour3("#2f4182");
      } else {
        setColour3("white");
      }
    } else if (window.innerWidth > 750 && window.innerHeight <= 850) {
      if (window.pageYOffset > window.innerHeight * 1.15) {
        setColour1("#2f4182");
      } else {
        setColour1("white");
      }

      if (window.pageYOffset > window.innerHeight * 1.14) {
        setColour2("#2f4182");
      } else {
        setColour2("white");
      }

      if (window.pageYOffset > window.innerHeight * 1.13) {
        setColour3("#2f4182");
      } else {
        setColour3("white");
      }
    } else if (window.innerWidth <= 650) {
      if (window.pageYOffset > window.innerHeight * 0.88) {
        setColour1("#2f4182");
      } else {
        setColour1("white");
      }

      if (window.pageYOffset > window.innerHeight * 0.87) {
        setColour2("#2f4182");
      } else {
        setColour2("white");
      }

      if (window.pageYOffset > window.innerHeight * 0.86) {
        setColour3("#2f4182");
      } else {
        setColour3("white");
      }
    }
    if (window.pageYOffset > window.innerHeight) {
      if (window.innerWidth < 520) {
        setHeaderBackground(headerStyle3);
      } else {
        setHeaderBackground(headerStyle2);
      }
    } else {
      setHeaderBackground(headerStyle1);
    }
  }

  useEffect(() => {
    return scrollY.onChange(() => update());
  });

  // useEffect(() => {
  //   return window.innerWidth.onChange(() => update());
  // });

  const controlLogo = () => {
    if (window.scrollY > 80) {
      setShowLogo(false);
    } else {
      setShowLogo(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlLogo);
    return () => {
      window.removeEventListener("scroll", controlLogo);
    };
  }, []);
  return (
    <div className='headerContainer'>
      <Link className='link' to='/'>
        <img className={showLogo ? "logo" : "logo hide"} src={logo} />
      </Link>

      <div className='phoneNav'>
        <div onClick={() => setMenuOpen(!menuOpen)}>
          <div className={menuOpen ? "hamburger open" : "hamburger"}>
            <div
              style={
                menuOpen
                  ? { backgroundColor: "white" }
                  : { backgroundColor: `${colour1}` }
              }
              className={menuOpen ? `line active1` : "line"}
            ></div>
            <div
              style={
                menuOpen
                  ? { backgroundColor: "white" }
                  : { backgroundColor: `${colour2}` }
              }
              className={menuOpen ? `line active3` : "line"}
            ></div>
            <div
              style={
                menuOpen
                  ? { backgroundColor: "white" }
                  : { backgroundColor: `${colour3}` }
              }
              className={menuOpen ? `line active2` : "line"}
            ></div>
          </div>
          <div className='headerBackground' style={headerBackground}></div>
        </div>

        {/* {menuOpen && ( */}
        <PhoneHeader menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        {/* )} */}
      </div>
    </div>
  );
}

export default Header;
