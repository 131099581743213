import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, useViewportScroll } from "framer-motion";

import "./performance.css";

const Performance = () => {
  const { scrollY } = useViewportScroll();
  const [hidden1, setHidden1] = useState(true);

  function update() {
    if (window.innerWidth > 1300) {
      if (scrollY?.current < window.innerHeight / 1.1) {
        setHidden1(true);
      } else {
        setHidden1(false);
      }
    } else if (window.innerWidth < 630) {
      if (scrollY?.current < 550) {
        setHidden1(true);
      } else {
        setHidden1(false);
      }
    } else {
      if (scrollY?.current < 750) {
        setHidden1(true);
      } else {
        setHidden1(false);
      }
    }
  }

  useEffect(() => {
    return scrollY.onChange(() => update());
  });

  const variants = {
    /** this is the "visible" key and it's correlating styles **/
    visible: { opacity: 1, y: 0 },
    /** this is the "hidden" key and it's correlating styles **/
    hidden: { opacity: 0, y: -120 },
    visible2: { opacity: 1, y: 0 },
    /** this is the "hidden" key and it's correlating styles **/
    hidden2: { opacity: 0, y: 0 },
  };
  return (
    <div className='performanceContainer'>
      <div id='performance'></div>
      <div className='performanceInnerContainer'>
        <motion.div
          variants={variants}
          animate={hidden1 ? "hidden" : "visible"}
          transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.8 }}
          className='performanceText'
        >
          {/* <div className='performancePDiv'> */}
          <h2>Backtest Performance</h2>

          <h3>
            Our simulated performance in backtesting has been independently
            verified. Once logged in, visit our "Investment Details" page to
            view this verification.
          </h3>

          <Link className='performanceButton' to='/'>
            Learn more
          </Link>
          {/* </div> */}
        </motion.div>

        <motion.div
          variants={variants}
          animate={hidden1 ? "hidden2" : "visible2"}
          transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
          className='dataContainer'
        >
          <div className='tbl-header'>
            <table cellPadding='0' cellSpacing='0' border='0'>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>
                    S&P500
                    <p className='thP'>Investor Net Return %</p>
                  </th>
                  <th>
                    Quantitative 200
                    <p className='thP'>Investor Net Return %</p>
                  </th>
                  <th>
                    Conviction 30
                    <p className='thP'>Investor Net Return %</p>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className='tbl-content'>
            <table cellPadding='0' cellSpacing='0' border='0'>
              <tbody>
                <tr>
                  <td>
                    1 Year
                    <p>Annualised Returns</p>
                  </td>
                  <td>16.26%</td>
                  <td>23.05%</td>
                  <td>21.92%</td>
                </tr>
                <tr>
                  <td>
                    5 Year
                    <p>Annualised Returns</p>
                  </td>
                  <td>12.94%</td>
                  <td>19.04%</td>
                  <td>23.80%</td>
                </tr>
                <tr>
                  <td>
                    10 Year
                    <p>Annualised Returns</p>
                  </td>
                  <td>11.56%</td>
                  <td>16.50%</td>
                  <td>20.17%</td>
                </tr>
                <tr>
                  <td>
                    20 Year
                    <p>Annualised Returns</p>
                  </td>
                  <td>5.37%</td>
                  <td>11.27%</td>
                  <td>13.61%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Performance;
