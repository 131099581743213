import React, { useCallback, useEffect, useState } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import "./disclaimer.css";

const Disclaimer = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);

    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div className='DisclaimerContainer'>
      <Particles
        id='tsparticles1'
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          style: {
            position: "absolute",
            height: "100%",
            width: "100vw",
            zIndex: 0,
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              onDiv: {
                selectors: "#repulse-div",
                enable: true,
                mode: "repulse",
                type: "rectangle",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 75,
                duration: 0.2,
              },
            },
          },
          particles: {
            color: {
              value: "#1f2c5c",
            },
            links: {
              color: "#1f2c5c",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              directions: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 70,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />

      <div className='innerDisclaimerContainer'>
        <h2>Disclaimer</h2>
        <p>
          Centrifuge Capital Pty Ltd (the Company) is a corporate authorised
          representative of Boutique Capital Pty Ltd (BCPL) AFSL 508011, CAR
          Number 001285674 The Company has taken all reasonable care in
          producing all the information contained in the website including but
          not limited to reports, tables, maps, diagrams and photographs.
          However, the Company will not be responsible for loss or damage
          arising from the use of this information. The contents of this website
          should not be used as a substitute for detailed investigations or
          analysis on any issues or questions the reader wishes to have
          answered. You may download the information for your own personal use
          or to inform others about our materials, but you may not reproduce or
          modify it without our express permission. To the extent to which this
          website contains advice it is general advice only and has been
          prepared by the Company for individuals identified as wholesale
          investors for the purposes of providing a financial product or
          financial service, under Section 761G or Section 761GA of the
          Corporations Act 2001 (Cth). The information in this website is not
          intended to be relied upon as advice to investors or potential
          investors and has been prepared without taking into account personal
          investment objectives, financial circumstances or particular needs.
          Recipients of this information are advised to consult their own
          professional advisers about legal, tax, financial or other matters
          relevant to the suitability of this information. Any investment(s)
          summarised in this website is subject to known and unknown risks, some
          of which are beyond the control of the Company and their directors,
          employees, advisers or agents. The Company does not guarantee any
          particular rate of return or the performance, nor does the Company and
          its directors personally guarantee the repayment of capital or any
          particular tax treatment. Past performance is not indicative of future
          performance. All investments carry some level of risk, and there is
          typically a direct relationship between risk and return. We describe
          what steps we take to mitigate risk (where possible) in the investment
          documentation, which must be read prior to investing. It is important
          to note risk cannot be mitigated completely. Whilst the contents of
          this website is based on information from sources which the Company
          considers reliable, its accuracy and completeness cannot be
          guaranteed. Data is not necessarily audited or independently verified.
          Any opinions reflect the Company’s judgment at this date and are
          subject to change. The Company has no obligation to provide revised
          assessments in the event of changed circumstances. To the extent
          permitted by law, BCPL, the Company and their directors and employees
          do not accept any liability for the results of any actions taken or
          not taken on the basis of information in this website, or for any
          negligent misstatements, errors or omissions.{" "}
        </p>
        <h4>Copyright 2021 Centrifuge Capital Pty Ltd</h4>
      </div>
    </div>
  );
};

export default Disclaimer;
