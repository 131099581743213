import logo from "./logo.svg";
import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.css";

import Home from "./components/pages/home";
// import LoginHeader from "./components/features/headers/loginHeader";
import Header from "./components/features/headers/homeHeader/index";
import Login from "./components/pages/Login";
import Signup from "./components/pages/Signup";
import Investors from "./components/pages/Investors";
import AboutUs from "./components/pages/aboutUs";
import Footer from "./components/features/home/footer";
import ExtraHeader from "./components/features/headers/extraHeader";
import Disclaimer from "./components/pages/disclaimer";

const httpLink = createHttpLink({
  uri: "/graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("id_token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Route exact path='/'>
          <Header />
          <Home />
        </Route>
        <Route exact path='/about'>
          <ExtraHeader />
          <AboutUs />
        </Route>
        <Route exact path='/disclaimer'>
          <ExtraHeader />
          <Disclaimer />
        </Route>
        <Route exact path='/login'>
          <Login />
        </Route>
        <Route exact path='/signup'>
          <Signup />
        </Route>
        <Route exact path='/investors'>
          <ExtraHeader />
          <Investors />
        </Route>
      </Router>
    </ApolloProvider>
  );
}

export default App;
