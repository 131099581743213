import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        _id
        firstName
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser(
    $firstName: String!
    $lastName: String!
    $postcode: String!
    $email: String!
    $password: String!
    $reportRequest: Boolean!
  ) {
    addUser(
      firstName: $firstName
      lastName: $lastName
      postcode: $postcode
      email: $email
      password: $password
      reportRequest: $reportRequest
    ) {
      token
      user {
        _id
        email
      }
    }
  }
`;

export const REQUEST_REPORT = gql`
  mutation reportRequested(
    $email: String!
    $salutation: String!
    $phone: String!
    $job: String!
    $company: String!
    $abn: String!
  ) {
    reportRequested(
      email: $email
      salutation: $salutation
      phone: $phone
      job: $job
      company: $company
      abn: $abn
    ) {
      reportRequest
    }
  }
`;
