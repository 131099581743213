import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { useMutation } from "@apollo/client";
import { ADD_USER } from "../utils/mutations";
import Modal from "react-modal";

import "./Signup.css";
import Auth from "../utils/auth";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "-3%",
    marginRight: "-50%",
    marginTop: "30px",
    transform: "translate(-50%, -50%)",
  },
};

const Signup = () => {
  const [termsAgree, setTermsAgree] = useState(false);
  const [privacyAgree, setPrivacyAgree] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const [termsErrorMsg, setTermsErrorMsg] = useState("");
  const [privacyErrorMsg, setPrivacyErrorMsg] = useState("");

  const [passwordState, setPasswordState] = useState("password");
  const [iconClass, setIconClass] = useState("eyeIcon");

  const [signupButton, setSignupButton] = useState("signupButton");
  const [loader, setLoader] = useState("hideLoader");

  const termsCheckboxHandler = () => {
    setTermsAgree(!termsAgree);
  };

  const privacyCheckboxHandler = () => {
    setPrivacyAgree(!privacyAgree);
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    if (termsAgree && privacyAgree) {
      setIsOpen(false);
    }
  }, [privacyAgree, termsAgree]);

  function openModal(event) {
    event.preventDefault();
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const bothTermsError = () => {
    setTermsErrorMsg(
      "You must accept the terms and conditions before proceeding"
    );
    setPrivacyErrorMsg(
      "You must accept the privacy agreement before proceeding"
    );
  };

  const termsError = () => {
    setTermsErrorMsg(
      "You must accept the terms and conditions before proceeding"
    );
    setPrivacyErrorMsg("");
  };

  const privacyError = () => {
    setTermsErrorMsg("");
    setPrivacyErrorMsg(
      "You must accept the privacy agreement before proceeding"
    );
  };

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    postcode: "",
    email: "",
    password: "",
    reportRequest: false,
  });
  const [addUser, { error, data }] = useMutation(ADD_USER);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handlePasswordState = (event) => {
    event.preventDefault();
    if (passwordState === "password") {
      setPasswordState("text");
      setIconClass("eyeIcon2");
    } else {
      setPasswordState("password");
      setIconClass("eyeIcon");
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (termsAgree === false && privacyAgree === false) {
      bothTermsError();
    } else if (termsAgree === true && privacyAgree === false) {
      privacyError();
    } else if (termsAgree === false && privacyAgree === true) {
      termsError();
    } else if (formState.password.length < 8) {
      setPasswordError(
        "Password must be greater than or equal to 8 characters"
      );
      setTermsErrorMsg("");
      setPrivacyErrorMsg("");
    } else {
      setTermsErrorMsg("");
      setPrivacyErrorMsg("");
      setSignupButton("hideSignupButton");
      setLoader("loader");
      try {
        const { data } = await addUser({
          variables: { ...formState },
        });

        Auth.login(data.addUser.token);
      } catch (e) {
        console.error(e);
        setLoader("hideLoader");
        setSignupButton("signupButton");
      }
    }
  };

  return (
    <div className='signUpContainer'>
      <div
        class='icon'
        onClick={() => {
          window.location.assign("/");
        }}
      >
        <div class='arrow'></div>
      </div>
      <div className='secondSignUpContainer'>
        <h2>Register</h2>
        <form onSubmit={handleFormSubmit}>
          <div className='inputContainer'>
            <input
              className='form-input'
              placeholder='First Name'
              name='firstName'
              type='text'
              value={formState.name}
              onChange={handleChange}
              required
            />
            <input
              className='form-input'
              placeholder='Last Name'
              name='lastName'
              type='text'
              value={formState.name}
              onChange={handleChange}
              required
            />
            <input
              className='form-input'
              placeholder='Your email'
              name='email'
              type='email'
              value={formState.email}
              onChange={handleChange}
              required
            />
            <input
              className='form-input'
              placeholder='Postcode '
              name='postcode'
              type='number'
              value={formState.name}
              onChange={handleChange}
              required
            />
            <input
              className='form-input'
              placeholder='Password (min 8)'
              name='password'
              type={passwordState}
              value={formState.password}
              onChange={handleChange}
              required
            />
          </div>
          <FontAwesomeIcon
            icon={faEye}
            size='1x'
            className={iconClass}
            onClick={handlePasswordState}
          />

          <div className='signupButtonContainer'>
            <button className={signupButton} onClick={openModal}>
              Terms & Conditions
            </button>
            <button className={signupButton} type='submit'>
              Register
            </button>
            <div className={loader}>
              <svg width='200' height='200' viewBox='0 0 100 100'>
                <polyline
                  className='line-cornered stroke-still'
                  points='0,0 100,0 100,100'
                  stroke-width='10'
                  fill='none'
                ></polyline>
                <polyline
                  className='line-cornered stroke-still'
                  points='0,0 0,100 100,100'
                  stroke-width='10'
                  fill='none'
                ></polyline>
                <polyline
                  className='line-cornered stroke-animation'
                  points='0,0 100,0 100,100'
                  stroke-width='10'
                  fill='none'
                ></polyline>
                <polyline
                  className='line-cornered stroke-animation'
                  points='0,0 0,100 100,100'
                  stroke-width='10'
                  fill='none'
                ></polyline>
              </svg>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel='Add File'
          >
            <div className='closeContainer'>
              <a className='modalFrontClose' onClick={closeModal}>
                x
              </a>
            </div>
            <div className='termsContainer'>
              <h2>Terms and Conditions</h2>
              <h4>
                We do not advise or make recommendations on financial or
                investment decisions.{" "}
              </h4>
              <p>
                Rather, if considering investing in the Capital Markets, our
                proprietary Quant/AI based stock selection platform has produced
                significantly better returns than the key market indicator, the
                S&P 500 (as backtested and independently verified over the last
                1, 5, 10 and 20 years).
              </p>
              <h4>
                Note that past performance is not a guarantee of future
                performance.
              </h4>
              <h4>Centrifuge Capital Pty. Ltd. (ACN 627 685 241)</h4>
              <p>
                This Investor Overview ("IO") dated September 2021 is issued by
                Centrifuge Capital Pty. Ltd. ("The Company"). The purpose of
                this IO is to provide information for prospective investors to
                decide whether they wish to receive an Information Memorandum
                from Boutique Capital Pty. Ltd. (“the Trustee”) with regards to
                investing in the subject matter of this IO (“the Investment”).
                Nothing contained in this IO is intended to be legally binding.
                It does not constitute a legally binding offer of to invest in
                any Investment offered by the Company or the Trustee. Any
                conduct or statement, whether prior to, part of, or subsequent
                to the issuance of this IO, is not, and is deemed not to be, an
                offer to contract or a binding undertaking of any kind by the
                Company or the Trustee (including without limitation,
                quasi-contractual rights, promissory estoppel, or rights with a
                similar legal basis). Only the specific terms and conditions,
                contained in a legally valid Information Memorandum, will have
                any legal effect. The only representations and warranties which
                may be relied on will be the limited representations and
                warranties (if any) given in the Information Memorandum and in
                no event will such a subscription or other related agreement
                contain any representation(s) as to the future performance of
                the Investment.
              </p>
              <h4>No disclosure required</h4>
              <p>
                This IO is intended to provide potential investors with
                information only and does not constitute a prospectus, short
                form prospectus or other disclosure document as defined in the
                Corporations Act 2001 ("the Act"). This IO has not been lodged
                with the Australian Securities and Investments Commission or any
                other government body. Any offer made in an Information
                Memorandum will only be available for acceptance by persons who
                qualify as sophisticated investors (as defined in section
                708(8)-(10) of the Act), professional investors (as defined in
                section 708(11) of the Act) or investors to whom disclosure is
                otherwise not required under Part 6D of the Act (collectively,
                "Qualified Investors"). The Trustee and the Company will not
                accept any investment from a person unless it is satisfied that
                the person is a Qualified Investor.
              </p>
              <h4>Foreign jurisdictions</h4>
              <p>
                This IO does not constitute, and may not be used for the purpose
                of, an offer or solicitation in any jurisdiction outside of the
                Commonwealth of Australia or in any circumstances in which an
                offer or solicitation is not authorised. No recipient of this IO
                in any jurisdiction may treat it as constituting an invitation
                to invest.
              </p>
              <h4>No responsibility for contents of document</h4>
              <p>
                To the maximum extent permitted by law, the Company and the
                Trustee (including all of its respective directors, employees or
                related bodies corporate):
              </p>
              <ul>
                <li>
                  {" "}
                  make no representation or warranty, express or implied, as to
                  the accuracy, reliability, reasonableness or completeness of
                  the contents of this IO;
                </li>
                <li>
                  {" "}
                  expressly disclaim any and all liability (whether arising from
                  negligence, under the Corporations Act or otherwise) for, or
                  based on, or relating to any such information (including any
                  estimates or projections) contained in this IO, or for any
                  errors in or omissions from this IO or for any written or oral
                  communications transmitted to the Recipient or other conduct
                  in the course of the Recipient’s evaluation of the Investment
                  (including its current and projected business), except for any
                  liability which cannot be excluded as a matter of law;
                </li>
                <li>
                  {" "}
                  are under no obligation to notify the Recipient or to provide
                  any further information to the Recipient if they or any of
                  them become aware of any inaccuracy, incompleteness or change
                  in any information in this IO;
                </li>
                <li>
                  {" "}
                  and accept no responsibility to inform any Recipient of any
                  matter arising or coming to their notice after the date of
                  this IO which may affect any matter referred to in this IO.
                </li>
              </ul>

              <h4>Forward looking statements</h4>
              <p>
                This IO includes forward looking statements which may contain
                the words "believe", "intend", "estimate", "expect" and words of
                similar meaning. All statements other than statements of
                historical facts included in this IO, including, without
                limitation, those regarding the Investment, the Company's and/or
                the Trustee’s financial position and business or investment
                strategy, plans and objectives are forward looking statements.
                Such forward looking statements involve known and unknown risks,
                uncertainties and other important factors that could cause the
                actual results, performance or achievements of the Investment,
                the Company and/or the Trustee to be materially different from
                past or projected future results, performance or achievements
                expressed or implied by such forward looking statements.
                Accordingly, there can be no assurance that such statements,
                estimates or projections will be realised. Nevertheless, past
                performance, however backtested, is no guarantee of future
                performance, and if you invest you are accepting that there is
                always an appreciable risk that your investment may be reduced
                or lost.
              </p>
              <h4>Independent advice recommended</h4>
              <p>
                This IO is not and should not be considered as a recommendation
                by the Company and/or the Trustee or any of their respective
                officers, employees, or advisers that any Recipient invest in
                the Investment, or the Company, or that such an investment is a
                suitable investment for the Recipient. Each Recipient should
                conduct and rely upon its own investigation and analysis of the
                investment and the Company, its business, the projections and of
                the information contained in this IO, and is advised to seek its
                own professional advice on the legal, financial, taxation and
                other consequences of investing in the Investment.
              </p>
              <p>
                Recipients are not to construe the contents of this IO as tax,
                legal or investment advice. The information contained herein is
                general information only and does not take into account
                individual objectives, financial situations or needs. Any
                investment in the Investment and/or the Company should be
                regarded as speculative and may not be appropriate for all
                persons or entities. Prior to any investment in the Investment
                and/or the Company, an investor should seek appropriate
                professional advice and should conduct his/her own independent
                investigation and analysis regarding any information contained
                in this IO or other Information Memorandum or Prospectus. A
                Recipient should rely on his or her own enquiries, in particular
                in obtaining his or her own legal, investment and tax advice in
                determining whether to invest in the Investment and/or Company.
              </p>
              <h4>No guarantee</h4>
              <p>
                Neither the Company nor any of its directors, officers,
                employees, advisers or representatives guarantee the rate of
                return of any investment in the Investment and/or the Company,
                the meeting of the investment objectives of the Investment
                and/or the Company or the performance of the Investment and/or
                the Company, nor do they guarantee repayment of committed
                capital.
              </p>
              <h4>Confidentiality</h4>
              <p>
                This IO is confidential and is being provided to Recipients for
                them to consider a future investment in the Investment and/or
                the Company. Its use for any other purpose is not permitted. It
                may not be reproduced or redistributed, in whole or in part, and
                its contents may not be disclosed to any person.
              </p>
              <h4>Privacy</h4>
              <p>
                The Company collects a variety of information from its clients,
                partners and other third parties from time to time. The Company
                processes and uses this information to provide services and to
                comply with security, legal and regulatory requirements. By
                receiving this IO, the recipient expressly and without
                limitation agrees to the Company collecting and using
                information as required and at the absolute sole discretion of
                the Company.
              </p>
              <div className='checkboxContainer'>
                <div className='individualCheckbox'>
                  <input
                    type='checkbox'
                    id='termsAgree'
                    onChange={termsCheckboxHandler}
                    checked={termsAgree}
                  />
                  <label className='termsCheckbox' htmlFor='agree'>
                    {" "}
                    <p>
                      I agree to the <b>terms and conditions</b>
                    </p>
                  </label>
                </div>
                <div className='individualCheckbox'>
                  <input
                    type='checkbox'
                    id='privacyAgree'
                    onChange={privacyCheckboxHandler}
                    checked={privacyAgree}
                  />
                  <label className='termsCheckbox' htmlFor='agree'>
                    {" "}
                    <p>
                      I agree to <b>privacy statement</b>
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </Modal>

          <div className='errorMsg'>{termsErrorMsg}</div>
          <div className='errorMsg'>{privacyErrorMsg}</div>
          <div className='errorMsg'>{passwordError}</div>
          {error && <div className='errorMsg'>{error.message}</div>}
        </form>
        <p>
          Already have an account? Login <Link to='/login'>Here</Link>
        </p>
      </div>
    </div>
  );
};

export default Signup;
